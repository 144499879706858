"use client";
import React from "react";
import MainTitle from "@/components/shared/MainTitle";
import BlogCard from "../Cards/Blog/BlogCard";
import { useGetAllBlogsQuery } from "@/redux/features/home/homeApiSlice";
import Loader from "@/components/Loader";

const LatestBlogs: React.FC = () => {
  const { data: blogs, isLoading, error } = useGetAllBlogsQuery();

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="flex justify-center items-center h-64">
          <Loader />
        </div>
      );
    }

    if (error) {
      return (
        <div
          className="bg-red-500 border border-red-400 text-white text-center px-4 py-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">Error!</strong>
          <span className="block sm:inline">
            {" "}
            Unable to load blog posts. Please try again later.
          </span>
        </div>
      );
    }

    if (!blogs || blogs.length === 0) {
      return (
        <div className="text-center text-gray-600">
          No blog posts available at the moment.
        </div>
      );
    }

    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {blogs.slice(0, 6).map((blog, index) => (
          <BlogCard index={index} key={blog.id} {...blog} />
        ))}
      </div>
    );
  };

  return (
    <section className="py-10">
      <div className="container mx-auto">
        <MainTitle subTitle="Our Blog" title="Latest Blog Posts" />
        <div className="mt-8">{renderContent()}</div>
      </div>
    </section>
  );
};

export default LatestBlogs;
