"use client";
import React, { useState } from "react";
import demoButtonBG from "@/public/images/assets/demoButtonBG.svg";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { useSendContactUsMessageMutation } from "@/redux/features/home/homeApiSlice";

import { BsStars } from "react-icons/bs";
import CustomToaster from "../CustomToaster";
import closeIcon from "@/public/images/icons/close.svg";
import Image from "next/image";
import userImage from "@/public/images/icons/User.svg";
import emailImage from "@/public/images/icons/Email.svg";
import Messages from "@/public/images/icons/Messages.svg";
import Phone from "@/public/images/icons/Phone.svg";
import City from "@/public/images/icons/City.svg";

type Props = {
  className?: string;
};

const DemoButton: React.FC<Props> = ({ className = "" }) => {
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    message: "",
    phone: "",
    company_name: "",
  });

  const [sendContactUsMessage] = useSendContactUsMessageMutation();
  const [isMainDialogOpen, setIsMainDialogOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await sendContactUsMessage({
        ...formData,
        is_request_demo: true,
        date: new Date(),
      }).unwrap();
      setIsSuccessDialogOpen(true);
      setIsMainDialogOpen(false);
      setFormData({
        full_name: "",
        email: "",
        message: "",
        phone: "",
        company_name: "",
      });
    } catch (error) {
      setIsMainDialogOpen(false);
      setIsErrorDialogOpen(true);
    }
  };

  return (
    <>
      <Dialog open={isMainDialogOpen} onOpenChange={setIsMainDialogOpen}>
        <DialogTrigger asChild>
          <button
            className={`demo-btn ${className ? className : ""}`}
            style={{
              backgroundImage: `linear-gradient(180deg, rgba(143, 105, 248, 0.00) -175%, rgba(143, 105, 248, 0.50) 140%), url(${demoButtonBG.src})`,
              boxShadow:
                "0px 1px 2px 0px rgba(143, 105, 248, 0.55), 0px 0px 0px 3px rgba(143, 105, 248, 0.17",
            }}
          >
            Request a demo
          </button>
        </DialogTrigger>
        <DialogContent className="rounded-2xl border border-[#1B1239] bg-[#07031A] p-4 max-w-2xl">
          {/* Close Button */}
          <div className="relative">
            <button
              className="absolute top-0 left-0 bg-[#110D24] p-4 rounded-lg"
              onClick={() => setIsMainDialogOpen(false)}
              aria-label="Close"
            >
              <Image src={closeIcon} alt="close" />
            </button>
            <div className="flex flex-col justify-center items-center gap-4">
              <div
                className="flex items-center gap-2 py-2 px-6 rounded-3xl"
                style={{
                  boxShadow: "0px 0px 0px 3px rgba(143, 105, 248, 0.10)",
                }}
              >
                <BsStars />
                <span>{"Request a demo"}</span>
              </div>
              <div className="text-center text-white text-xl md:text-xl font-semibold capitalize max-w-2xl">
                {"Have any questions ? We would love to hear from you."}
              </div>
              <div className="text-center text-[#928cb4]">
                You can contact us anytime regarding any queries or deals,dont
                hesitate to clear your doubts before trying our product.
              </div>
            </div>
            <form
              className="space-y-6"
              onSubmit={handleSubmit}
              data-aos-easing="ease-in-out"
            >
              <div className="flex flex-col md:flex-row gap-6">
                <div className="flex-1">
                  <label htmlFor="full_name" className="sr-only">
                    Full Name
                  </label>
                  <div className="relative">
                    <Image
                      src={userImage}
                      alt="user"
                      className="absolute left-3 top-1/2 transform -translate-y-1/2 text-purple-400"
                    />{" "}
                    <input
                      type="text"
                      id="full_name"
                      name="full_name"
                      value={formData.full_name}
                      onChange={handleChange}
                      placeholder="Full Name"
                      className="h-15 w-full bg-[#07031A] border border-[#1B1239] text-white placeholder-[#938DB5] pl-10 pr-6 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                    />
                  </div>
                </div>
                <div className="flex-1">
                  <label htmlFor="phone" className="sr-only">
                    Your Number
                  </label>
                  <div className="relative">
                    <Image
                      src={Phone}
                      width={24}
                      height={24}
                      alt="user"
                      className="absolute left-3 top-1/2 transform -translate-y-1/2 text-purple-400"
                    />{" "}
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Your Number"
                      className="h-15 w-full bg-[#07031A] border border-[#1B1239] text-white placeholder-[#938DB5] pl-10 pr-6 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                    />
                  </div>
                </div>
              </div>

              <div className="flex-1">
                <label htmlFor="company_name" className="sr-only">
                  Company Name
                </label>
                <div className="relative">
                  <Image
                    src={City}
                    alt="City"
                    width={24}
                    height={24}
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-purple-400"
                  />{" "}
                  <input
                    type="text"
                    id="company_name"
                    name="company_name"
                    value={formData.company_name}
                    onChange={handleChange}
                    placeholder="Company Name"
                    className="h-15 w-full bg-[#07031A] border border-[#1B1239] text-white placeholder-[#938DB5] pl-10 pr-6 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                  />
                </div>
              </div>

              <div className="flex-1">
                <label htmlFor="email" className="sr-only">
                  Your Email
                </label>
                <div className="relative">
                  <Image
                    src={emailImage}
                    alt="user"
                    width={22}
                    height={22}
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-purple-400"
                  />{" "}
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Your Email"
                    className="h-15 w-full bg-[#07031A] border border-[#1B1239] text-white placeholder-[#938DB5] pl-10 pr-6 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                  />
                </div>
              </div>

              <div className="flex-1">
                <label htmlFor="message" className="sr-only">
                  Your Message
                </label>
                <div className="relative">
                  <Image
                    src={Messages}
                    alt="user"
                    width={24}
                    height={24}
                    className="absolute left-3 top-4 text-purple-400"
                  />{" "}
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Your Message"
                    rows={6}
                    className="h-40 resize-none w-full bg-[#07031A] border border-[#1B1239] text-white placeholder-[#938DB5] pl-10 pr-6 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                  ></textarea>
                </div>
              </div>

              <div className="space-x-4">
                <button
                  type="submit"
                  className="px-6 py-2 bg-brightPurple text-white rounded-lg hover:bg-purple-600 transition-colors"
                >
                  Send Message
                </button>
                <button
                  type="button"
                  className="px-6 py-2 bg-[#07031A] border border-[#1B1239] text-white rounded-lg hover:bg-purple-900 transition-colors"
                  onClick={() => setIsMainDialogOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </DialogContent>
      </Dialog>
      <CustomToaster
        open={isSuccessDialogOpen}
        onOpenChange={setIsSuccessDialogOpen}
        title="Your message was sent successfully"
        subTitle="We will contact you as soon as possible to answer your question"
        type="success"
      />
      <CustomToaster
        open={isErrorDialogOpen}
        onOpenChange={setIsErrorDialogOpen}
        title="A problem occurred while sending"
        subTitle="There was a problem while sending the message. Try again"
        type="error"
      />
    </>
  );
};

export default DemoButton;
