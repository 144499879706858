"use client";
import React, { useState } from "react";
import {
  FaUser,
  FaPhone,
  FaEnvelope,
  FaCommentAlt,
  FaBuilding,
} from "react-icons/fa";
import { useSendContactUsMessageMutation } from "@/redux/features/home/homeApiSlice";
import MainTitle from "@/components/shared/MainTitle";
import CustomToaster from "../CustomToaster";
import Image from "next/image";
import userImage from "@/public/images/icons/User.svg";
import emailImage from "@/public/images/icons/Email.svg";
import Messages from "@/public/images/icons/Messages.svg";
import Phone from "@/public/images/icons/Phone.svg";
import City from "@/public/images/icons/City.svg";
type Props = {};

const ContactUS = (props: Props) => {
  return (
    <section id="contact" className="flex flex-col gap-4 overflow-x-clip">
      <MainTitle
        subTitle="CONTACT US"
        title="Have any questions? We would love to hear from you."
      />
      <div className="container text-center pb-10">
        <ContactForm />
      </div>
    </section>
  );
};

export const ContactForm = () => {
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    message: "",
    phone: "",
    company_name: "",
  });

  const [sendContactUsMessage] = useSendContactUsMessageMutation();
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await sendContactUsMessage({ ...formData, date: new Date() }).unwrap();
      setIsSuccessDialogOpen(true);
      setFormData({
        full_name: "",
        email: "",
        message: "",
        phone: "",
        company_name: "",
      });
    } catch (error) {
      setIsErrorDialogOpen(true);
    }
  };

  return (
    <>
      <form
        className="space-y-6"
        onSubmit={handleSubmit}
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
      >
        <div className="flex flex-col md:flex-row gap-6">
          <div className="flex-1">
            <label htmlFor="full_name" className="sr-only">
              Full Name
            </label>
            <div className="relative">
              <Image
                src={userImage}
                alt="user"
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-purple-400"
              />
              <input
                type="text"
                id="full_name"
                name="full_name"
                value={formData.full_name}
                onChange={handleChange}
                placeholder="Full Name"
                className="h-20 w-full bg-[#1c1738] text-white placeholder-purple-300 pl-10 pr-6 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
            </div>
          </div>
          <div className="flex-1">
            <label htmlFor="phone" className="sr-only">
              Your Number
            </label>
            <div className="relative">
              <Image
                src={Phone}
                width={24}
                height={24}
                alt="user"
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-purple-400"
              />{" "}
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Your Number"
                className="h-20 w-full bg-[#1c1738] text-white placeholder-purple-300 pl-10 pr-6 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
            </div>
          </div>
        </div>

        <div className="flex-1">
          <label htmlFor="company_name" className="sr-only">
            Company Name
          </label>
          <div className="relative">
            <Image
              src={City}
              alt="City"
              width={24}
              height={24}
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-purple-400"
            />{" "}
            <input
              type="text"
              id="company_name"
              name="company_name"
              value={formData.company_name}
              onChange={handleChange}
              placeholder="Company Name"
              className="h-20 w-full bg-[#1c1738] text-white placeholder-purple-300 pl-10 pr-6 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
          </div>
        </div>

        <div className="flex-1">
          <label htmlFor="email" className="sr-only">
            Your Email
          </label>
          <div className="relative">
            <Image
              src={emailImage}
              alt="user"
              width={22}
              height={22}
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-purple-400"
            />{" "}
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Your Email"
              className="h-20 w-full bg-[#1c1738] text-white placeholder-purple-300 pl-10 pr-6 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
          </div>
        </div>

        <div className="flex-1">
          <label htmlFor="message" className="sr-only">
            Your Message
          </label>
          <div className="relative">
            <Image
              src={Messages}
              alt="user"
              width={24}
              height={24}
              className="absolute left-3 top-4 text-purple-400"
            />
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Your Message"
              rows={6}
              className="h-60 resize-none w-full bg-[#1c1738] text-white placeholder-purple-300 pl-10 pr-6 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
            ></textarea>
          </div>
        </div>

        <div className="flex justify-end space-x-4">
          <button
            type="button"
            className="px-6 py-2 bg-[#1c1738] text-white rounded-lg hover:bg-purple-900 transition-colors"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-6 py-2 bg-brightPurple text-white rounded-lg hover:bg-purple-600 transition-colors"
          >
            Send Message
          </button>
        </div>
      </form>
      <CustomToaster
        open={isSuccessDialogOpen}
        onOpenChange={setIsSuccessDialogOpen}
        title="Your message was sent successfully"
        subTitle="We will contact you as soon as possible to answer your question"
        type="success"
      />
      <CustomToaster
        open={isErrorDialogOpen}
        onOpenChange={setIsErrorDialogOpen}
        title="A problem occurred while sending"
        subTitle="There was a problem while sending the message. Try again"
        type="error"
      />
    </>
  );
};

export default ContactUS;
