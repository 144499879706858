"use client";

import React, { useMemo } from "react";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { QueryClient, QueryClientProvider } from "react-query";

const ReduxProvider = ({ children }: { children: React.ReactNode }) => {
  // Memoize QueryClient instance to avoid re-creating it on every render
  const queryClient = useMemo(() => new QueryClient(), []);

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        {children}{" "}
      </QueryClientProvider>
    </Provider>
  );
};

export default ReduxProvider;
