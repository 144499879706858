import Image from "next/image";
import Link from "next/link";
import React from "react";
import { IoTimeOutline } from "react-icons/io5";

type Props = {
  index: number;
  id: string;
  created_at: string;
  title: string;
  description: string;
  image: string;
  tags?: string[];
};

const BlogCard: React.FC<Props> = ({
  index,
  id,
  created_at,
  title,
  description,
  image,
  tags,
}) => {
  return (
    <Link href={`/blogs/${id}`} className="block">
      <div
        className="p-5 rounded-lg backdrop-blur-md flex flex-col justify-between space-y-4 hover:shadow-lg transition-shadow duration-300"
        style={{
          border: "0.89px solid #231646",
          background:
            "linear-gradient(180deg, rgba(27, 22, 38, 0.20) 0%, rgba(27, 22, 38, 0.00) 100%)",
        }}
        data-aos="fade-up"
        data-aos-delay={index * 100}
      >
        <div
          className="relative"
          data-aos="fade-up"
          data-aos-delay={index * 100 + 100}
        >
          <div className="absolute inset-0 bg-[#140e30] rounded-full blur-2xl" />
          <Image
            className="w-full h-60 object-contain rounded-lg relative z-10"
            src={image}
            alt={title}
            width={400}
            height={250}
            data-aos="fade-in"
            data-aos-delay={index * 100 + 200}
          />
        </div>
        <div className="flex flex-col space-y-2">
          <div
            className="flex items-center gap-2"
            data-aos="fade-up"
            data-aos-delay={index * 100 + 300}
          >
            <IoTimeOutline className="text-lightPurple" />
            <span className="text-lightPurple text-sm">{created_at}</span>
          </div>
          <div className="space-y-1">
            <h3
              className="text-white text-lg md:text-xl lg:text-[21px] font-medium leading-tight"
              data-aos="fade-up"
              data-aos-delay={index * 100 + 400}
            >
              {title}
            </h3>
            <p
              className="text-lightPurple text-sm md:text-base lg:text-lg leading-snug line-clamp-3"
              data-aos="fade-up"
              data-aos-delay={index * 100 + 500}
            >
              {description}
            </p>
          </div>
        </div>
        <div className="flex flex-wrap gap-2">
          {tags &&
            tags.map((tag, index) => (
              <span
                key={index}
                className="px-4 py-1 bg-[#151128] rounded-full border border-[#9747ff]/5 text-lightPurple text-sm font-['Somar Sans']"
              >
                {tag}
              </span>
            ))}
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;
