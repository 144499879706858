"use client";
import Image from "next/image";
import Link from "next/link";
import React, { useState, useEffect, useCallback } from "react";
import { usePathname } from "next/navigation";
import mainLogo from "@/public/images/logos/mainLogo.svg";
import DemoButton from "../Buttons/DemoButton";
import { FiMenu, FiX } from "react-icons/fi";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeHash, setActiveHash] = useState(""); // State for storing current hash
  const pathname = usePathname();

  const navItems = [
    { name: "Home", path: "/" },
    { name: "Why Us", path: "/#why-Us" },
    { name: "Features", path: "/#features" },
    { name: "FAQ's", path: "/#FAQ" },
    { name: "Blogs", path: "/blogs" },
  ];

  const handleHashChange = useCallback(() => {
    setActiveHash(window.location.hash);
  }, []);

  useEffect(() => {
    window.addEventListener("hashchange", handleHashChange);
    handleHashChange(); // Set initial hash on mount

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [handleHashChange]);

  const toggleMenu = () => setIsOpen(!isOpen);

  const isActive = useCallback(
    (item: { name: string; path: string }) => {
      const fullPath = pathname + activeHash;
      return fullPath === item.path || (item.path === "/" && fullPath === "/");
    },
    [pathname, activeHash]
  );

  const handleNavClick = useCallback((path: string) => {
    if (path.startsWith("/#")) {
      setActiveHash(path.slice(1)); // Immediately update activeHash for hash links
    } else {
      setActiveHash(""); // Clear hash for non-hash links
    }
    setIsOpen(false); // Ensure the menu is closed on navigation
  }, []);

  return (
    <header className="sticky top-4 h-fit z-50 left-0 container">
      <div className="container flex items-center justify-between rounded-[70px] border border-[#291E5699] bg-[#03001433] backdrop-blur-sm py-4 my-4 px-6">
        <div className="flex items-center gap-6 flex-1">
          <Link
            href="/"
            className="logo flex items-center justify-center gap-2"
            onClick={() => handleNavClick("/")}
          >
            <Image src={mainLogo} alt="Vulnvision" />
            <h1 className="hidden md:block md:text-xl">Vulnvision</h1>
          </Link>
          <nav className="hidden lg:block flex-1">
            <ul className="flex items-center gap-4">
              {navItems.map((item) => (
                <li
                  key={item.name}
                  className={`bg-[#FFFFFF0D] px-4 py-2 rounded-3xl ${
                    isActive(item) ? "ring ring-violet-400 ring-opacity-75" : ""
                  }`}
                >
                  <Link
                    href={item.path}
                    onClick={() => handleNavClick(item.path)}
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className="hidden md:flex items-center gap-2 md:gap-4">
          <DemoButton />
        </div>
        <button
          className="lg:hidden text-2xl mx-2"
          aria-label="Toggle menu"
          onClick={toggleMenu}
        >
          {isOpen ? <FiX /> : <FiMenu />}
        </button>
      </div>

      {/* Mobile Sidebar */}
      <div
        className={`fixed inset-y-0 right-0 z-50 w-64 bg-darkPurple p-6 transition-transform duration-300 ease-in-out transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        } lg:hidden`}
      >
        <button
          className="absolute top-4 right-4 text-2xl"
          aria-label="Close"
          onClick={toggleMenu}
        >
          <FiX />
        </button>
        <nav className="mt-8">
          <ul className="space-y-4 flex flex-col ">
            {navItems.map((item) => (
              // <li
              //   key={item.name}
              //   className={`px-4 py-2 rounded-3xl ${
              //     isActive(item) ? "bg-violet-600" : "bg-[#FFFFFF0D]"
              //   }`}
              // >
              <li key={item.name}>
                {" "}
                <Link
                  className={`px-4 py-2 rounded-3xl ${
                    isActive(item) ? "bg-violet-600" : "bg-[#FFFFFF0D]"
                  }`}
                  href={item.path}
                  onClick={() => handleNavClick(item.path)}
                >
                  {item.name}
                </Link>
              </li>
              // </li>
            ))}
          </ul>
        </nav>
        <div className="mt-8 space-y-4 border-t pt-8">
          <DemoButton className="w-full" />
        </div>
      </div>
    </header>
  );
};

export default Header;
