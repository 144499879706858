// "use client";
// import React, { useState, useEffect, useRef } from "react";
// import MainTitle from "@/components/shared/MainTitle";
// import howToWork from "@/public/images/assets/howItWork.png";
// import checkIcon from "@/public/images/icons/check.svg";
// import Image from "next/image";
// import { motion } from "framer-motion";
// import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

// gsap.registerPlugin(ScrollTrigger);

// const Index = () => {
//   const [activeIndex, setActiveIndex] = useState(0);
//   const cardsRef = useRef<HTMLDivElement>(null);

//   const sliderData = [
//     {
//       number: "01.",
//       title: "Sign up",
//       description:
//         "Schedule a personalized demo to explore Vulnvision’s capabilities tailored to your needs.",
//       mainContent: {
//         title: "Request a Demo and Sign Up",
//         description:
//           "Experience Vulnvision’s full potential by scheduling a demo. Follow these steps to get started:",
//         bullets: [
//           "Visit the Demo Page on our website",
//           "Navigate to the 'Request a Demo' section",
//           "Fill out the form with your contact details and organization’s needs",
//           "Submit the form and receive a confirmation email with demo details",
//         ],
//       },
//     },
//     {
//       number: "02.",
//       title: "Assets Discovery",
//       description:
//         "Automatically scan and catalog your entire digital footprint, including domains, IPs, and web applications.",
//       mainContent: {
//         title: "Discover and Catalog Your Digital Assets",
//         description:
//           "Utilize Vulnvision to gain comprehensive insights into your digital environment. Follow these steps to manage your assets effectively:",
//         bullets: [
//           "Start the automated scanning process to explore your digital footprint",
//           "Identify and map all digital assets, including domains, IPs, and web applications",
//           "Catalog discovered assets for organized management and future reference",
//           "Receive continuous updates to keep your asset inventory current",
//         ],
//       },
//     },
//     {
//       number: "03.",
//       title: "Continuous Monitoring",
//       description:
//         "Keep your asset inventory current with real-time updates and detect changes instantly.",
//       mainContent: {
//         title: "Real-Time Security Monitoring",
//         description:
//           "Ensure continuous oversight of your digital assets with Vulnvision’s real-time monitoring capabilities. Here’s what you can expect:",
//         bullets: [
//           "Receive instant alerts for any detected changes",
//           "Detect and respond to changes in real time",
//           "Enable real-time monitoring to stay informed",
//           "Stay updated with continuous tracking and instant change detection",
//         ],
//       },
//     },
//     {
//       number: "04.",
//       title: "Scan Assets",
//       description:
//         "Identify and prioritize security weaknesses with comprehensive scans and risk scoring.",
//       mainContent: {
//         title: "Conduct Comprehensive Asset Scans",
//         description:
//           "Utilize Vulnvision to uncover and prioritize security vulnerabilities in your digital assets. Follow these steps to perform thorough scans and risk assessments:",
//         bullets: [
//           "Initiate the asset scan to start the vulnerability assessment",
//           "Detect the type of scan required based on asset categories",
//           "Analyze vulnerabilities with detailed risk scoring",
//           "Prioritize and address security weaknesses based on the analysis",
//         ],
//       },
//     },
//   ];

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setActiveIndex((prevIndex) => (prevIndex + 1) % sliderData.length);
//     }, 12000);

//     // GSAP animation for cards
//     if (cardsRef.current) {
//       const cards = cardsRef.current.children;
//       gsap.fromTo(
//         cards,
//         { y: 20, opacity: 0 },
//         {
//           y: 0,
//           opacity: 1,
//           stagger: 0.2,
//           duration: 0.7,
//           ease: "power3.out",
//           scrollTrigger: {
//             trigger: cardsRef.current,
//             start: "top bottom-=150",
//             toggleActions: "play none none reverse",
//           },
//         }
//       );
//     }
//     return () => clearInterval(interval);
//   }, [sliderData.length]);

//   return (
//     <div className="flex flex-col items-center justify-center gap-8 text-white py-10 overflow-x-clip">
//       <MainTitle subTitle="How it works" title="Discover Our Approach" />
//       <div className="container grid grid-cols-1 md:grid-cols-3 gap-12">
//         <motion.div
//           initial={{ opacity: 0, x: -50 }}
//           animate={{ opacity: 1, x: 0 }}
//           transition={{ duration: 0.8 }}
//           className="w-full"
//         >
//           <Image
//             src={howToWork}
//             alt="How it works"
//             layout="responsive"
//             className="rounded-lg shadow-2xl"
//           />
//         </motion.div>
//         <motion.div
//           key={activeIndex}
//           initial={{ opacity: 0, x: 50 }}
//           animate={{ opacity: 1, x: 0 }}
//           transition={{ duration: 0.8, delay: 0.2 }}
//           className="col-span-1 md:col-span-2 flex-col justify-start items-start gap-6 inline-flex w-full"
//         >
//           <div className="text-[#8f69f8] text-base font-semibold uppercase">
//             how it works: {sliderData[activeIndex].number}
//           </div>
//           <h2 className="text-white text-4xl md:text-5xl font-bold mb-4">
//             {sliderData[activeIndex].mainContent.title}
//           </h2>
//           <p className="text-[#928cb4] text-xl font-light mb-6">
//             {sliderData[activeIndex].mainContent.description}
//           </p>
//           <div className="flex-col justify-start items-start gap-4 flex">
//             {sliderData[activeIndex].mainContent.bullets.map(
//               (bullet, index) => (
//                 <motion.div
//                   key={index}
//                   initial={{ opacity: 0, y: 20 }}
//                   animate={{ opacity: 1, y: 0 }}
//                   transition={{ duration: 0.5, delay: index * 0.1 }}
//                   className="text-[#928cb4] text-xl flex items-center gap-3"
//                 >
//                   <Image
//                     src={checkIcon}
//                     alt="check icon"
//                     width={24}
//                     height={24}
//                   />
//                   {bullet}
//                 </motion.div>
//               )
//             )}
//           </div>
//         </motion.div>
//       </div>

//       {/* Cards with progress bars */}
//       <div
//         ref={cardsRef}
//         className="container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4"
//       >
//         {sliderData.map((slide, index) => (
//           <div
//             key={index}
//             className={`px-2 rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105 ${
//               index === activeIndex ? "" : ""
//             }`}
//           >
//             <div className="w-full bg-[#2a2a4a] h-1 rounded-full overflow-hidden mb-2">
//               <motion.div
//                 className="bg-[#8f69f8] h-full rounded-full"
//                 initial={{ width: "0%" }}
//                 animate={{ width: index === activeIndex ? "100%" : "0%" }}
//                 transition={{ duration: 12, ease: "easeIn" }}
//               ></motion.div>
//             </div>
//             <div className="text-[#8f69f8] text-sm font-semibold mb-2">
//               {slide.number}
//             </div>
//             <h3 className="text-white text-xl font-semibold mb-2">
//               {slide.title}
//             </h3>
//             <p className="text-[#928cb4] text-sm mb-4">{slide.description}</p>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Index;
"use client";
import React, { useState, useEffect } from "react";
import MainTitle from "@/components/shared/MainTitle";
import howToWork from "@/public/images/assets/howItWork.png";
import checkIcon from "@/public/images/icons/check.svg";
import Image from "next/image";

const Index = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [progressWidth, setProgressWidth] = useState(0); // State for progress bar width

  const sliderData = [
    {
      number: "01.",
      title: "Sign up",
      description:
        "Schedule a personalized demo to explore Vulnvision’s capabilities tailored to your needs.",
      mainContent: {
        title: "Request a Demo and Sign Up",
        description:
          "Experience Vulnvision’s full potential by scheduling a demo. Follow these steps to get started:",
        bullets: [
          "Visit the Demo Page on our website",
          "Navigate to the 'Request a Demo' section",
          "Fill out the form with your contact details and organization’s needs",
          "Submit the form and receive a confirmation email with demo details",
        ],
      },
    },
    {
      number: "02.",
      title: "Assets Discovery",
      description:
        "Automatically scan and catalog your entire digital footprint, including domains, IPs, and web applications.",
      mainContent: {
        title: "Discover and Catalog Your Digital Assets",
        description:
          "Utilize Vulnvision to gain comprehensive insights into your digital environment. Follow these steps to manage your assets effectively:",
        bullets: [
          "Start the automated scanning process to explore your digital footprint",
          "Identify and map all digital assets, including domains, IPs, and web applications",
          "Catalog discovered assets for organized management and future reference",
          "Receive continuous updates to keep your asset inventory current",
        ],
      },
    },
    {
      number: "03.",
      title: "Continuous Monitoring",
      description:
        "Keep your asset inventory current with real-time updates and detect changes instantly.",
      mainContent: {
        title: "Real-Time Security Monitoring",
        description:
          "Ensure continuous oversight of your digital assets with Vulnvision’s real-time monitoring capabilities. Here’s what you can expect:",
        bullets: [
          "Receive instant alerts for any detected changes",
          "Detect and respond to changes in real time",
          "Enable real-time monitoring to stay informed",
          "Stay updated with continuous tracking and instant change detection",
        ],
      },
    },
    {
      number: "04.",
      title: "Scan Assets",
      description:
        "Identify and prioritize security weaknesses with comprehensive scans and risk scoring.",
      mainContent: {
        title: "Conduct Comprehensive Asset Scans",
        description:
          "Utilize Vulnvision to uncover and prioritize security vulnerabilities in your digital assets. Follow these steps to perform thorough scans and risk assessments:",
        bullets: [
          "Initiate the asset scan to start the vulnerability assessment",
          "Detect the type of scan required based on asset categories",
          "Analyze vulnerabilities with detailed risk scoring",
          "Prioritize and address security weaknesses based on the analysis",
        ],
      },
    },
  ];

  useEffect(() => {
    // Initially set the progress bar width to 0%
    setProgressWidth(0);

    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % sliderData.length);
    }, 12000);

    // Set progress width to 100% after a brief delay
    setTimeout(() => {
      setProgressWidth(100);
    }, 100); // Start transition after 100ms

    return () => clearInterval(interval);
  }, [sliderData.length]);

  return (
    <div className="flex flex-col items-center justify-center gap-8 text-white py-10 overflow-x-clip">
      <MainTitle subTitle="How it works" title="Discover Our Approach" />
      <div className="container grid grid-cols-1 md:grid-cols-3 gap-12">
        <div data-aos="fade-up" className="w-full">
          <Image
            src={howToWork}
            alt="How it works"
            layout="responsive"
            className="rounded-lg shadow-2xl"
          />
        </div>
        <div
          key={activeIndex}
          data-aos="fade-up"
          data-aos-delay="200"
          className="col-span-1 md:col-span-2 flex-col justify-start items-start gap-6 inline-flex w-full"
        >
          <div className="text-[#8f69f8] text-base font-semibold uppercase">
            how it works: {sliderData[activeIndex].number}
          </div>
          <h2 className="text-white text-4xl md:text-5xl font-bold mb-4">
            {sliderData[activeIndex].mainContent.title}
          </h2>
          <p className="text-[#928cb4] text-xl font-light mb-6">
            {sliderData[activeIndex].mainContent.description}
          </p>
          <div className="flex-col justify-start items-start gap-4 flex">
            {sliderData[activeIndex].mainContent.bullets.map(
              (bullet, index) => (
                <div
                  key={index}
                  data-aos="fade-up"
                  data-aos-delay={`${index * 100}`}
                  className="text-[#928cb4] text-xl flex items-center gap-3"
                >
                  <Image
                    src={checkIcon}
                    alt="check icon"
                    width={24}
                    height={24}
                  />
                  {bullet}
                </div>
              )
            )}
          </div>
        </div>
      </div>

      {/* Cards with progress bars */}
      <div
        className="container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4"
        data-aos="fade-up"
      >
        {sliderData.map((slide, index) => (
          <div
            key={index}
            className={`px-2 rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105`}
            data-aos="fade-up"
            data-aos-delay={`${index * 100}`}
          >
            <div className="w-full bg-[#2a2a4a] h-1 rounded-full overflow-hidden mb-2">
              <div
                className="bg-[#8f69f8] h-full rounded-full"
                style={{
                  width: index === activeIndex ? `${progressWidth}%` : "0%",
                  transition: "width 12s ease-in-out",
                }}
              ></div>
            </div>
            <div className="text-[#8f69f8] text-sm font-semibold mb-2">
              {slide.number}
            </div>
            <h3 className="text-white text-xl font-semibold mb-2">
              {slide.title}
            </h3>
            <p className="text-[#928cb4] text-sm mb-4">{slide.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Index;
